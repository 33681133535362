import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {Component} from "react";
import {withRouter} from "react-router-dom";
import {StaticContext} from 'react-router';
import './container.css';

type State = {
    loading: boolean,
    error: string
};

class PublicMainContainer extends Component<RouteComponentProps<{}, StaticContext>, State, {}> {

    state = {
        loading: false,
        error: ''
    };

    render() {

        return (
            <React.Fragment>
                <header>
                    <div className="collapse bg-dark" id="navbarHeader">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-8 col-md-7 py-4">
                                    <h4 className="text-white">Address</h4>
                                    <p className="text-white">
                                        65 Corban Ave SE STE 12<br/>
                                        Concord, NC 28025
                                    </p>
                                </div>
                                <div className="col-sm-4 offset-md-1 py-4">
                                    <h4 className="text-white">Contact</h4>
                                    <ul className="list-unstyled">
                                        <li className="text-white">704-253-8883</li>
                                        <li className="text-white">jeckste@gmail.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbar navbar-dark bg-dark shadow-sm">
                        <div className="container d-flex justify-content-between">
                            <a href={"#a"} onClick={e => e.preventDefault()}
                               className="navbar-brand d-flex align-items-center">

                                <strong>Concord Office Suites</strong>
                            </a>
                            <a href={"tel:+17042538883"} className="navbar-brand">
                                <strong className={""}>704-253-8883</strong>
                            </a>
                        </div>
                    </div>
                </header>
                {this.props.children}
                <footer className="text-muted">
                    <div className="container">
                        <p className="float-right">
                            <a href={"#a"} onClick={e => e.preventDefault()}>Back to top</a>
                        </p>
                        <p>
                            Norstein Properties LLC<br/>
                            65 Corban Ave SE STE 12<br/>
                            Concord, NC 28025
                        </p>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default withRouter(PublicMainContainer);