import './style.css';
import * as React from 'react';
import {Unit} from "../../types";
import {useHistory} from "react-router";


export type PropertyCardProps = {
    unit: Unit,
    showMap: Function
}

const PropertyCard: React.FunctionComponent<PropertyCardProps> = ({unit, showMap}) => {

    const history = useHistory();
    const doRedirectToPage = () => {
        history.push({
            pathname: "/properties/" + unit.id
        })
    }



    return (
        <React.Fragment>
            <div className="col-md-4">
                <div className="property card mb-4 shadow-sm" onClick={() => {
                    doRedirectToPage()
                }}>
                    <img alt="" src={unit.thumbnail} height={225} width={100 + '%'}/>
                    <div className="card-body">
                        <p className="card-text">
                            {unit.addressLine1}<br/>
                            {unit.city}, {unit.state} {unit.zip}
                        </p>
                        <p>
                            {unit.sqft} sqft<br/>
                            {unit.dimensions}
                        </p>
                        <p className={"property-notes"}>{unit.notes}</p>

                        <div className="d-flex justify-content-between align-items-center">
                            <span className="badge badge-primary">{unit.status}</span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PropertyCard;