import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {StaticContext} from 'react-router';
import PropertyCard from "../../Components/PropertyCard";
import {Property, Unit, settings} from "../../types";
import * as QueryString from "query-string";

type State = {
    loading: boolean,
    error: string,
    units: Unit[],
    properties: Property[],
    displayMap: boolean
};


class PropertiesContainer extends Component<RouteComponentProps<{}, StaticContext>, State, {}> {

    state = {
        loading: false,
        error: '',
        properties: [],
        units: [],
        displayMap: false
    };


    async componentDidMount() {
        const params = QueryString.parse(this.props.location.search);
        const response = await fetch(settings.dataPath, {});
        const data = await response.json();
        let units = data.units;
        let properties = data.properties;
        let filteredData = [];
        if (params.locationId) {
            units.forEach((u: Unit) => {
                if (u.propertyId === parseInt(params.locationId as string)) {
                    filteredData.push(u);
                }
            });
        } else {
            filteredData = units;
        }
        this.setState({units: filteredData, properties: properties})
    }

    doShowMap = (value: boolean) => {
        this.setState({displayMap: value})
    }

    render() {
        if (!this.state.units || this.state.units.length === 0) return <div>Loading...</div>
        return (
            <>
                <main role="main">

                    <section id="map" className="text-center"
                             style={{display: (this.state.displayMap ? 'block' : 'none')}}>

                    </section>

                    <div className="album py-5 bg-light">
                        <div className="container">

                            <div className="row">
                                {
                                    this.state.units.map((u: Unit) => (
                                        <PropertyCard unit={u} key={u.id} showMap={this.doShowMap} />
                                    ))
                                }

                            </div>
                        </div>
                    </div>

                </main>
            </>
        );
    }
}

export default withRouter(PropertiesContainer);