import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import PublicMainContainer from "./Containers/PublicMainContainer";
import PropertiesContainer from "./Containers/PropertiesContainer";
import PropertyPage from "./Components/PropertyPage";

function App() {
    return (
        <Router>
            <Switch>
                <PublicMainContainer>
                    <Route path={"/"} exact>

                        <PropertiesContainer/>

                    </Route>
                    <Route path={"/properties/:propertyId"} exact>
                        <PropertyPage/>
                    </Route>
                </PublicMainContainer>
            </Switch>
        </Router>
    );
}

export default App;
