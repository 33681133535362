import * as React from 'react';
import "./style.css"
import {useCallback, useEffect, useState} from "react";
import {Unit, settings} from "../../types";
import {useParams} from "react-router";
import {Link} from 'react-router-dom';


const PropertyPage: React.FunctionComponent = () => {

    const [units, setUnits] = useState(new Array<Unit>());
    const [image, setImage] = useState();
    const [counter, setCounter] = useState(0);
    const {propertyId} = useParams();

    const getUnit = () => {
        return units.find(u => u.id === parseInt(propertyId));
    };

    useEffect(() => {

        fetch(settings.dataPath, {})
            .then(response => {
                return response.json()
            })
            .then(data => {
                let us:[Unit] = data.units;
                setUnits(us);
                let unit = us.find(u => u.id === parseInt(propertyId));
                if (unit && unit.images) {
                    setImage(unit.images[0]);
                }
            });

    }, [propertyId]);


    const unit = getUnit();
    return (unit) ? <>
        <main role="main">
            <div className="album mt-3 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <Link to={"/"}>Back to properties</Link>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            {unit.addressLine1}<br/>
                            {unit.city}, {unit.state} {unit.zip}<br/>
                            <span className="badge badge-primary">{unit.status}</span>
                        </div>
                        <div className="col-md-4">
                            {unit.sqft} sqft<br/>
                            {unit.dimensions}
                        </div>
                        <div className="col-md-4 property-notes">
                            {unit.notes}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div>Click image for next image...</div>
                            <img className={"property"} width="100%"
                                 src={image}
                                 onClick={() => {
                                     let unit = getUnit();
                                     if (unit && unit.images) {
                                         let c = counter + 1;
                                         if (unit.images.length <= c) {
                                             c = 0;
                                         }
                                         setCounter(c);
                                         setImage(unit.images[c]);
                                     }
                                 }}/>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    </> : <></>;
};

export default PropertyPage;