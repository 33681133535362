
export let settings = {
    dataPath: "/data/properties_1.3.json"
}

export type Address = {
    addressLine1: string,
    addressLine2: string,
    city: string,
    stateCode: string,
    zip: string
}


export type Property = {
    id: string,
    lat: number,
    lng: number,
    name: string,
    address: Address
}

export type Unit = {
    id: number,
    name: string,
    address: Address,
    propertyId: number,
    addressLine1: string,
    city: string,
    state: string,
    zip: string,
    sqft: number,
    dimensions: string,
    notes: string,
    status: string,
    perSqftRate: number,
    perMonthRate: number,
    images: string[],
    thumbnail: string
}

